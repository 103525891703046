'use client'

import React from 'react'
import { useRouter } from 'next/navigation'
import { signIn, signOut } from 'next-auth/react'

import { Button } from '@/components/ui/button'
import { getLogoutUrl, revalidateCache } from '@/app/(marketing)/actions'

import { DropdownMenuItem } from './ui/dropdown-menu'

export const handleLogout = async (idToken?: string) => {
  await revalidateCache('/member')
  await signOut({ redirect: false })

  const logoutUrl = await getLogoutUrl(idToken)

  window.location.href = logoutUrl
}

export const LoginButton = React.forwardRef<
  HTMLButtonElement,
  React.ButtonHTMLAttributes<HTMLButtonElement>
>(({ className, children, ...props }, ref) => (
  <Button
    ref={ref}
    variant="primary"
    onClick={() => {
      revalidateCache('/member')
      signIn('okta')
    }}
    className={className}
    {...props}
  >
    {children || 'Log in'}
  </Button>
))
LoginButton.displayName = 'LoginButton'

export const LoginLink = React.forwardRef<
  HTMLButtonElement,
  React.ButtonHTMLAttributes<HTMLButtonElement>
>(({ className, children, ...props }, ref) => (
  <Button
    ref={ref}
    variant="primary-breadcrumb-link"
    onClick={() => {
      revalidateCache('/member')
      signIn('okta')
    }}
    className={className}
    {...props}
  >
    {children || 'Log in'}
  </Button>
))
LoginLink.displayName = 'LoginLink'

export const RegisterButton = React.forwardRef<
  HTMLButtonElement,
  React.ButtonHTMLAttributes<HTMLButtonElement>
>(({ className, children, ...props }, ref) => {
  const router = useRouter()

  const handleRegister = () => {
    revalidateCache('/member')
    router.push('/register')
  }

  return (
    <Button
      ref={ref}
      variant="primary-outline"
      className={className}
      onClick={handleRegister}
      {...props}
    >
      {children || 'Register'}
    </Button>
  )
})

RegisterButton.displayName = 'RegisterButton'

export const LogoutButton = React.forwardRef<
  HTMLButtonElement,
  React.ButtonHTMLAttributes<HTMLButtonElement> & {
    idToken?: string
    inDropdown?: boolean
  }
>(({ className, idToken, children, inDropdown = false, ...props }, ref) => {
  const buttonContent = (
    <Button
      ref={ref}
      variant="primary-outline"
      onClick={() => handleLogout(idToken)}
      className={className}
      {...props}
    >
      {children || 'Log out'}
    </Button>
  )

  if (inDropdown) {
    return <DropdownMenuItem asChild>{buttonContent}</DropdownMenuItem>
  }

  return buttonContent
})

LogoutButton.displayName = 'LogoutButton'
